<!--广告管理-->
<template>
    <!--    广告投放管理-->
    <div class="Manage" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="广告名称">
                            <el-input placeholder="广告名称" v-model="form.search" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户名称">
                            <el-select placeholder="客户名称" v-model="form.customerCode" filterable clearable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="ct in meta.customers"
                                    :label="ct.name"
                                    :value="ct.code"
                                    :key="ct.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="广告类型">
                            <el-select placeholder="广告类型" v-model="form.type" clearable>
                                <el-option label="请选择" value="" />
                                <el-option label="视频" value="1" />
                                <el-option label="图片" value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="广告时长">
                            <el-select placeholder="客户名称" v-model="form.timeLength" clearable>
                                <el-option label="请选择" value="" />
                                <el-option label="15s" value="15" />
                                <el-option label="30s" value="30" />
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="启用状态">
                            <el-select placeholder="启用状态" v-model="form.status" clearable>
                                <el-option label="请选择" value="" />
                                <el-option label="启用" value="1" />
                                <el-option label="停用" value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="跳转类型">
                            <el-select placeholder="跳转类型" v-model="form.jumpType" clearable>
                                <el-option label="请选择" value="" />
                                <el-option label="不跳转" value="1" />
                                <el-option label="打开大图" value="2" />
                                <el-option label="跳转外链" value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.advert.manage.open')">
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.advert.manage.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleBatchEnable"
                size="small"
                v-if="hasPrivilege('menu.advert.manage.edit')"
                :disabled="disableBatchEnable"
                >批量启用
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="advertManage"
                ref="advertManage"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left">
                    <!--                    type='selection'复选框 -->
                </el-table-column>
                <el-table-column label="序号" width="60" type="index" fixed="left" />

                <el-table-column label="编码" width="200" prop="code" />
                <el-table-column label="广告名称" min-width="160" prop="name" />
                <el-table-column label="客户名称" min-width="140" prop="customerName" />
                <el-table-column label="广告类型" width="70" prop="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="跳转类型" width="100" prop="jumpType" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.jumpType | jumpType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="广告内容" width="160" prop="content" :show-overflow-tooltip="true" />
                <el-table-column label="时长(s)" width="80" prop="timeLength" />
                <el-table-column label="状态" width="60" prop="status">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status | status }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="90" prop="creator" :show-overflow-tooltip="true" />
                <el-table-column label="创建时间" width="160" prop="createTime" />

                <el-table-column label="操作" min-width="280" header-align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="success"
                            v-if="scope.row.status == 0 && hasPrivilege('menu.advert.manage.edit')"
                            @click="handleEnable(scope.row.code)"
                            >启用
                        </el-button>
                        <el-button
                            size="mini"
                            v-if="scope.row.status == 1 && hasPrivilege('menu.advert.manage.edit')"
                            @click="handleDisable(scope.row.code)"
                            >停用
                        </el-button>
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handlePreview(scope.row.type, scope.row.uploadAccessory)"
                            v-if="hasPrivilege('menu.advert.manage.open')"
                            >预览
                        </el-button>
                        <el-button
                            size="mini"
                            :disabled="scope.row.status == 1"
                            type="warning"
                            v-if="hasPrivilege('menu.advert.manage.edit')"
                            @click="handleEdit(scope.row)"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            :disabled="scope.row.status == 1"
                            type="danger"
                            v-if="hasPrivilege('menu.advert.manage.delete')"
                            @click="handleDelete(scope.row.code)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="提示" :visible.sync="previews.dialogVisible" :before-close="beforeClose">
            <el-image v-if="previews.image" :src="previews.image" />
            <video controls v-if="previews.video" width="100%" height="100%" autoplay="true" muted="true" loop="true">
                <source :src="previews.video" type="video/mp4" />
            </video>
        </el-dialog>
        <!--预览广告组件-->
        <advert-preview ref="advertPreview" />
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import EfEndDate from '../../components/EfEndDate';
import EfStartDate from '../../components/EfStartDate';
import AdvertPreview from 'views/menu/advert/manage/AdvertPreview';
import moment from 'moment';
import { patchAfterConfirmed } from 'request/http';

export default {
    name: 'Manage',
    components: { EfEndDate, EfStartDate, AdvertPreview },
    data() {
        return {
            form: {
                //当前时间减去一年后的时间
                startTime: moment().subtract(1, 'year').format('YYYY-MM-DD'),
                status: '',
                timeLength: '',
                type: '',
                search: '',
                page: 1,
                limit: Util.Limit,
                jumpType: '',
            },
            meta: {
                customers: [],
                depts: [],
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            previews: {
                dialogVisible: false,
                image: '',
                video: '',
            },

            tableData: [],
            selectedBizArr: [],
            url: {
                page: '/advert/manage/page',
                batchEnable: '/advert/manage/batchStatusOn',
                delete: '/advert/manage/delete',
                changeStatus: '/advert/manage/statusChange',
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '停用';
            }
            if (status == '1') {
                return '启用';
            }
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '图片';
            }
            if (type == '1') {
                return '视频';
            }
        },
        jumpType(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '1') {
                return '不跳转';
            }
            if (type == '2') {
                return '打开大图';
            }
            if (type == '3') {
                return '跳转外链';
            }
        },
    },
    mounted() {
        this.handleQuery();
        this.inits();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    computed: {
        disableBatchEnable() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const noneDisableStatusIndex = this.selectedBizArr.findIndex((e) => {
                //不等于停用状态
                return e.status !== 0;
            });
            return noneDisableStatusIndex >= 0;
        },
    },
    methods: {
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        inits() {
            const _this = this;
            UrlUtils.Customer(_this, (data) => {
                _this.meta.customers = data;
            });
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.advert.manage.create', ['广告管理', '广告管理', '批量创建广告']);
        },
        handleExport() {},
        async handleBatchEnable() {
            const paramsStr = this.$refs['advertManage'].selection.map((s) => s.code).join(',');
            await patchAfterConfirmed(this.url.batchEnable, { codes: paramsStr });
            this.handleQuery();
        },

        beforeClose() {
            this.previews.image = '';
            this.previews.video = '';
            this.previews.dialogVisible = false;
        },
        async handleEnable(code) {
            await patchAfterConfirmed(this.url.changeStatus, { code: code, status: 1 });
            this.handleQuery();
        },
        async handleDisable(code) {
            await patchAfterConfirmed(this.url.changeStatus, { code: code, status: 0 });
            this.handleQuery();
        },
        handlePreview(advertFileType, advertUrl) {
            this.$refs.advertPreview.showDialog(advertFileType, advertUrl);
        },
        handleEdit(row) {
            Util.nameJump(this, 'menu.advert.manage.edit', ['广告管理', '广告管理', '编辑广告'], {
                form: row,
                code: row.code,
            });
        },
        handleDelete(code) {
            UrlUtils.DeleteRemote(this, this.url.delete, code);
        },
    },
};
</script>

<style scoped>
.Manage .el-form-item {
    margin-bottom: 0;
}
</style>
